.login-container {
  background: radial-gradient(circle, #d63648 0%, #ac071e 100%);
  height: 100vh;
  max-height: 100%;
  width: 100%;
}

.login-box {
  position: absolute;
  width: 438px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-box-shadow: 0 6px 7px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 6px 7px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 6px 7px 0 rgba(0, 0, 0, 0.2);
  padding: 66px 64px 56px 64px !important;
  border-radius: 10px;
  border-top: 0;
  color: #666;
  background-color: #fbfbfa;
}

.login-logo {
  margin-bottom: 53px;
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.login-form {
  margin-top: 40px !important;
}

.login-form input {
  height: 45px;
  padding: 0px 12px 2px 12px;
}

.login-btn {
  width: 100%;
  height: 45px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 11px;
  margin-top: 20px !important;
}

@media (max-width: 768px) {
  .login-box {
    width: 90%;
  }
}