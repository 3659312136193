.watermarks {
  position: absolute;   
  top: 0;
  left: -200px;
  width: calc(100% + 200px);
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-template-rows: repeat(auto-fill, 150px);
  gap: 50px;
  pointer-events: none;
  opacity: 0.1;
  overflow: hidden;
}

.watermark {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  gap: 2px;
  padding: 24px;
  width: max-content;
  transform: rotate(-15deg);
}

.watermark .sicepat-logo {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.watermark .text {
  border-left: 1px solid #000;
  padding: 0 8px;
}

.watermark .text .employee-name {
  font-size: 22px;
  line-height: 24px;
  font-weight: 600;
}
.watermark .text .branch-name {
  font-size: 22px;
  line-height: 24px;
  font-weight: 500;
}
