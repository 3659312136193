body .p-datatable .p-datatable-tbody > tr {
  background: transparent;
}
body .p-datatable .p-datatable-scrollable-header,
body .p-datatable .p-datatable-thead > tr > th {
  background-color: rgba(240, 240, 240, 0.5);
}

body .p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: rgba(245, 245, 245, 0.3);
}
