@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';


.badge.badge-warning {
  background-color: #ffc107 !important;
  color: #212529 !important;
}
.badge.badge-danger {
  background-color: #dc3545 !important;
  color: #fff !important;
}
.badge.badge-success {
  background-color: #28a745 !important;
  color: #fff !important;
}